import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import table1 from '../images/table1.png'
import table2 from '../images/table2.png'

import { injectIntl } from 'gatsby-plugin-intl';

const EmpresasPage = ({ intl }) => (
	<Layout>
		<SEO title="Legales" />
        <div className="container container-legales">
            <h2 className="text-center">Términos y condiciones de uso del linkingPos</h2>
            <p>Estas condiciones generales (&ldquo;Condiciones de Uso&rdquo;) establecen las reglas para el uso por parte de cualquier persona/empresa (el &ldquo;Usuario&rdquo;) del servicio de promociones de cobro Pos ofrecido por linkingPos (de Inspirational Growth S.A.), en adelante linkingPos.</p>
            <p>El uso del linkingPos implica la aceptaci&oacute;n expresa de estas Condiciones de Uso y de las pol&iacute;ticas y principios incorporados a las mismas, por lo que el Usuario acepta expresamente que sus derechos y obligaciones en relaci&oacute;n con el uso del sistema linkingPos se rijan por estas Condiciones de Uso.</p>
            <h3>1- linkingPos</h3>
            <p>linkingPos es el servicio de gestión y procesamiento electrónico de pagos ofrecido que permite al Usuario recibir pagos en forma presencial y no presencial con las tarjetas de crédito y débito aceptadas por linkingPos (las “Tarjetas”). Para ello es necesario el uso de un lector de tarjetas que permite la lectura del chip, la banda magnética o el sistema contact less de las Tarjetas (el “Lector”).</p>
            <h4>Alta de Clientes - Registro de cuentas</h4>
            <p><strong>a) Registro.</strong> Todo Usuario que desee utilizar el Servicio debe obtener el alta de clientes en linkingPos y aceptar estas Condiciones Generales. Para dar el alta del cliente o registrarse es obligatorio completar el formulario de registro en todos sus campos con datos válidos e información personal/empresa exacta, precisa y verdadera ("Datos Personales/Empresa"). El Usuario asume el compromiso de actualizar los Datos Personales/Empresa conforme resulte necesario. LinkingPos no se responsabiliza por la certeza de los Datos Personales/Empresa provistos por sus Usuarios. Los Usuarios garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los Datos Personales/Empresa ingresados. Toda la información y los Datos Personales/Empresa ingresados por el Usuario tienen carácter de declaración jurada.</p>
            <p>linkingPos se reserva el derecho de solicitar comprobantes y/o información adicional a efectos de corroborar la información entregada por un Usuario en materia de Datos Personales/Empresa, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados.</p>
            <p>linkingPos se reserva el derecho de rechazar una solicitud de registro o de cancelar o suspender, temporal o definitivamente una Cuenta (conforme es definida a continuación), en caso de detectar incongruencias o inconsistencias en la información provista por un Usuario o en caso de detectar actividades sospechosas, sin que tal decisión genere para el Usuario derechos de indemnización o resarcimiento.</p>
            <p><strong>b) Cuenta en INSPIRATIONAL GROWTH S.A. </strong>Al dar el alta o registrarse, el Usuario abrirá una cuenta en linkingPos (en adelante, la "Cuenta"). La Cuenta permitirá al Usuario utilizar el servicio de recibir pagos por ventas de bienes o servicios y utilizar las Herramientas de Ventas, mediante el débito o acreditación de dinero en la Cuenta, como también utilizar o aplicar cualquier otra funcionalidad que en un futuro se ofrezca al Cliente/Usuario, sujeto a los términos y condiciones que se establezcan para cada funcionalidad. El Usuario accederá a su Cuenta en linkingPos mediante el ingreso de su dirección de correo (usuario) y clave de seguridad personal elegida ("Clave de Ingreso"). El Usuario se obliga a mantener la confidencialidad de su Clave de Ingreso.</p>
            <p>Se aclara que, en todos los casos, la Cuenta es personal, única e intransferible, y será asignada a un solo Usuario y está prohibida su venta, cesión o transferencia bajo ningún título. El Usuario no podrá permitir ni autorizar el uso de su Cuenta por terceras personas. La Cuenta estará denominada en letras y cualquier referencia a dinero en linkingPos se entenderá hecha a la moneda de curso legal en la República Argentina.</p>
            <h3>2- Uso del servicio linkingPos</h3>
            <p>Para hacer uso de linkingPos, el Usuario deberá recibir de parte de linkingPos una terminal Pos o lector de tarjetas. El Usuario será el único responsable por la conservación en buen uso del dispositivo y de la conexión telefónica o Internet, y por cualquier daño que pueda sufrir el dispositivo derivado del mal uso del terminal Pos.</p>
            <p>linkingPos podrá en cualquier momento requerir información y/o documentación del Usuario (incluyendo identificación oficial, CUIT, CUIL, comprobante de domicilio, entre otros) para evaluar el uso del terminal Pos.  Podrá, a su sola discreción, denegar, cancelar o suspender el uso del terminal Pos. al Usuario en caso de detectar inconsistencias en la documentación provista o en caso de detectar alguna actividad sospechosa de ilegalidad, fraude o cualquier otro acto contrario a los presentes Términos y Condiciones de Uso.</p>
            <h3>3- Operaciones de cobro con el servicio linkingPos</h3>
            <h5>3.1. Cobro de venta de bienes y/o prestación de servicios </h5>
            <p>El Usuario acepta expresamente y se obliga a usar el servicio linkingPos únicamente con el fin de recibir pagos efectuados con las Tarjetas de crédito y/o débito por la venta de bienes y/o prestación de servicios comercializados u ofrecidos por el Usuario. El Usuario asume la obligación de no utilizar el servicio linkingPos para otros fines fuera de los previstos en estos Términos y Condiciones de Uso.</p>
            <p>El Usuario acepta expresamente y se obliga a cumplir con todas las leyes y la normativa aplicables en relación a las operaciones de cobro y a cualquier relación entre el Usuario y el comprador que dé lugar a la operación de cobro.</p>
            <h5>3.3. Medidas de Seguridad</h5>
            <p>El Usuario deberá tomar todos los recaudos necesarios y convenientes para cerciorarse de que el uso de la Tarjeta por parte del comprador para una operación de pago es legítimo, conforme las leyes y normas aplicables, y los usos comercialmente aceptados.</p>
            <p>En cada operación de pago a través del servicio linkingPos, el Usuario deberá verificar que la identidad del portador de la Tarjeta coincida con el nombre grabado en la misma, mediante el pedido de exhibición de un documento oficial con foto y firma que acredite identidad, verificando, también, en base a la foto del documento, que la persona que ahí aparece sea la misma que está realizando la transacción.</p>
            <p>Asimismo, el Usuario deberá verificar los siguientes extremos: (i) que la Tarjeta no se encuentre vencida; (ii) que la Tarjeta cuente con la firma de su titular en la cintilla de firma que aparece al reverso de la tarjeta, tal que coincida con la firma plasmada en el documento que acredita la identidad del titular y en el Comprobante Digital (conforme el término se define en el apartado 3.4) , y sin que presente tachaduras, enmiendas, borrones o señales de firmas encimadas; (iii) que la Tarjeta no tenga ningún indicio de haber sido modificada o alterada; (iv) que el número de tarjeta o sus últimos cuatro dígitos, según el caso, grabados en el anverso de la Tarjeta coincidan con el número impreso en el reverso de la misma; y (v) que los datos del Comprobante Digital coincidan con los de la Tarjeta.</p>
            <p>En todos los casos, el Usuario acepta expresamente y se compromete a observar las características de seguridad para la aceptación de las Tarjetas con el uso del servicio linkingPos. El Usuario no deberá aceptar ninguna transacción con Tarjetas en caso de que no cumplan con las características de seguridad o en caso de duda sobre dicho cumplimiento. El Usuario acepta expresamente que será el único y exclusivo responsable por cualquier transacción realizada con Tarjetas robadas o cualquier uso del servicio linkingPos realizado en infracción a estas Condiciones de Uso.</p>
            <h5>3.4. Comprobante de la Transacción</h5>
            <p>El Usuario proporcionará al comprador una copia del comprobante de la operación de cobro realizada con el uso del servicio linkingPos, que será emitido por la terminal POS y al mismo tiempo debe exigir la firma del comprobante original por parte del comprador, y archivar el mismo como respaldo del pago. linkingPos no proporcionará ningún comprobante. El Usuario deberá conservar una copia del Comprobante de cada operación por el plazo de un (1) año en caso de operaciones al contado y dos (2) años en caso de operaciones en cuotas.</p>
            <h5>3.5. Contracargos</h5>
            <p>El Usuario reconoce y acepta que usa el servicio linkingPos a su propio riesgo, sin ninguna cobertura contracargos, por cualquier mecanismo de protección.</p>
            <p>Por ello, en caso de que un comprador realice una anulación, contracargo, desconocimiento o reversión de una operación de cobro realizada por el Usuario con el servicio linkingPos, trasladará al Usuario el contracargo, sin excepción.</p>
            <p>A tal fin, linkingPos podrá debitar los fondos contracargados del saldo de la cuenta del Usuario, y/o de saldos que pudieran ingresar en el futuro en la Cuenta del Usuario en IinkingPos. El Usuario autoriza expresamente a linkingPos a debitar de su Cuenta el importe necesario para cubrir la anulación, contracargo, desconocimiento o reversión.</p>
            <p>El Usuario se compromete a mantener indemne a linkingPos de todo reclamo y/o daño que pueda sufrir como consecuencia directa o indirecta del incumplimiento por el Usuario de las obligaciones previstas en la presente cláusula.</p>
            <h3>4- Promociones Bancarias</h3>
            <p>Las promociones bancarias vigentes se detallan en Anexo I, todas las promociones del anexo I están habilitadas y configuradas para ser usadas a través del servicio linkingPos. Ver ANEXO I.</p>
            <p>Las condiciones de las promociones vigentes con los bancos emisores de las tarjetas, implican un descuento al Cliente, que lo aporta el Usuario del servicio linkingPos sobre el Precio de Lista y la financiación de las cuotas sin interés acordadas en cada promoción, están a cargo de linkingPos a través de sus acuerdos con las entidades financieras.</p>
            <p>El Precio de Lista permite al Usuario absorber los costos financieros asociados a las Promociones bancarias, comisiones de tarjetas, etc.). Si bien la política de fijación de precios de venta es decisión de cada cliente, el Usuario debe considerar, en el análisis de rentabilidad de su negocio, los costos y comisiones asociados al uso de Promociones bancarias (Ver Liquidaciones y plazos de acreditación de fondos)</p>
            <p>El cliente no debe pagar interés sobre el Precio de lista, es decir, si al momento de la venta se cobra al usuario final un “recargo por costo financiero”, no se estaría operando bajo las condiciones del acuerdo, y el Usuario será responsable por los costos financieros extras que se originen por operar fuera del acuerdo. El usuario siempre debe respetar los acuerdos y aplicarlos sobre los precios de lista, de lo contrario linkingPos tiene la potestad de excluirlos de la promoción en cuestión, y/o disponer el retiro del lector.</p>
            <h3>5- Liquidaciones de Pago y Plazo de acreditación de fondos</h3>
            <p>linkingPos es el encargado de realizar las liquidaciones de Pago de los Fondos derivados de las operaciones de cobro realizadas a través del linkingPos y la acreditación de dichos fondos en la cuenta del Usuario.</p>
            <p>Las Liquidaciones de Pago se realizan sobre los precios detallados en los comprobantes que emite el lector por el uso del linkingPos. Los porcentajes de descuento en concepto de Costos Financieros dependerán de diversos factores como el emisor de la tarjeta, las cuotas en que se realiza la compra, etc. Al momento de la firma del contrato dichos porcentajes son, en el caso de uso de tarjetas de créditos, linkingPos descontará un 22% (más IVA, si correspondiere) en concepto de Costos financieros, y para el uso de Tarjetas de débito, descontará un 5% (más IVA, si correspondiere). Dichos valores porcentuales, en concepto de Costos financieros, pueden variar periódicamente y los mismos deben ser comunicados a los usuarios con anterioridad a la concreción de la venta utilizando el servicio linkingPos. linkingPos emitirá una factura por cuenta y orden del usuario con el detalle de los descuentos realizados.</p>
            <p>Estos fondos quedarán disponibles (i) en caso de que el pago se haya efectuado con una tarjeta de débito, en el momento de la acreditación, o (ii) en caso de que el pago se haya efectuado con una tarjeta de crédito, a partir de los 14 días hábiles.</p>
            <p>Los Fondos podrán disponerse antes de dicho plazo si el Usuario solicitara un Adelanto de Fondos.</p>
            <h3>6- Tarifas</h3>
            <p>El único costo que tendrá el Usuario por el uso del linkingPos se encuentra sujeto a ciertas tarifas que son:</p>
            <p className="text-center"><img src={table1} alt=""/></p>
            <p>Para tus ventas con tarjeta de crédito puedes elegir cuál será la tarifa que pagarás y cuándo estará disponible el dinero.</p>
            <p className="text-center"><img src={table2} alt=""/></p>
            <p> linkingPos podrá modificar la tarifa en cualquier momento, mediante la comunicación de los nuevos cargos a los Usuarios que incluye el detalle de tarifas por el linkingPos, cuyo enlace se provee con el párrafo precedente.</p>
            <h3>7- Uso del Lector</h3>
            <p>Es responsabilidad del Usuario cuidar la terminal POS (el Lector), custodiarlo con la mayor diligencia y usarlo únicamente para el uso aquí establecido. El Usuario deberá mantener el Lector fuera del alcance de niños.</p>
            <p>linkingPos no asume ninguna responsabilidad por los daños que sufra el Lector, los cuales serán de responsabilidad exclusiva del Usuario. A modo puramente enunciativo, linkingPos no será responsable por los daños que hubieran sido causados por incumplimiento de las precauciones o por cualquier causa atribuible al Usuario por su culpa o negligencia (tales como, accidentes, mal uso, modificación o intento de modificación del Lector) y/o por daños provocados por cualquier tercero.</p>
            <p>En ningún caso podrá el Usuario intentar reparar o de ninguna otra manera manipular el Lector.</p>
            <p>
            El robo o extravío del Lector será responsabilidad exclusiva del Cliente/Usuario. En cualquiera de esos casos, a fin de continuar operando con el linkingPos I , el Usuario deberá reportar los inconvenientes para que linkingPos pueda proveer el servicio técnico correspondiente. El Usuario no podrá vender, ceder o transferir de cualquier modo el Lector a terceros. El Usuario tampoco podrá permitir su uso por un tercero, excepto a sus empleados o representantes con el sólo fin de recibir pagos a favor del Usuario. En todo caso, el Usuario asume la responsabilidad exclusiva por el uso del Lector por parte de personas distintas del Usuario, sin perjuicio del derecho de linkingPos a reclamar los daños y perjuicios derivados del incumplimiento de la presente cláusula.
            </p>
            <h3>8- Usos prohibidos</h3>
            <p>El Usuario acepta expresamente y asume la obligación de no realizar ninguna de las siguientes prácticas en el uso del servicio linkingPos:</p>
            <ul>
            <ol>(i) Exigir límites mínimos de precio para la aceptación de pagos con Tarjeta.</ol>
            <ol>(ii) Aplicar precios superiores o comisiones adicionales por el uso de una Tarjeta en relación a los precios que establece con cualesquiera otros medios de pago.</ol>
            <ol>(iii) Utilizar el servicio linkingPos fuera de Argentina y/o procesar transacciones en otra moneda que no sea la moneda de curso legal argentina.</ol>
            <ol>(iv) Discriminar entre las Tarjetas en cualquiera de sus formas y por cualquier motivo, sin perjuicio de los descuentos, cuotas u otras acciones promocionales que pudieran existir con respecto a la utilización de cualquiera de las Tarjetas a través de POS.</ol>
            <ol>(v) Aceptar pagos por transacciones no originadas de una operación directa entre el Usuario y el comprador.</ol>
            <ol>(vi) Aceptar pagos que el Usuario sepa o deba saber que son fraudulentos o no autorizados por el titular de la Tarjeta, de acuerdo con las normas legales y los presentes Términos y Condiciones. de Cliente/Usuario.</ol>
            <ol>(vii) Usar el servicio linkingPos. para procesar pagos para terceros o transferir fondos para terceros.</ol>
            <ol>(viii) Usar el servicio linkingPos. con la sola finalidad de desembolsar efectivo, ya sea en beneficio del comprador, del Usuario o de terceros. El Usuario no deberá efectuar operaciones que puedan considerarse o verse como un adelanto de efectivo o préstamo. Se considerará adelanto de efectivo a cualquier operación en la que un Usuario agregue Fondos a su Cuenta de linkingPos mediante operaciones de cobro con Tarjetas propias y/o de personas humanas o jurídicas relacionadas al Usuario para retirar dichos fondos, y en general a cualquier operación de cobro de Tarjetas en la medida de que no se correspondan con una operación de venta o prestación de servicios auténtica.</ol>

            </ul>
            <h3>9- Responsabilidad del Usuario</h3>
            <p>El Usuario es el único y exclusivo responsable por el uso del servicio linkingPos, incluyendo las consecuencias de su uso. El Usuario es responsable de todas las operaciones realizadas con el Lector que se le hubiera entregado.</p>
            <p>Asimismo, el Usuario es el único y exclusivo responsable por su actividad, sea comercial, profesional, de servicios u obra y/o de los contenidos, bienes o servicios que ofrezca.</p>
            <p>El Usuario se obliga a cumplir con las leyes y reglamentaciones aplicables y con los Términos y Condiciones de Uso vigentes del servicio linkingPos.</p>
            <p>El Usuario se obliga a cumplir con las leyes y normativa aplicables respecto a toda información que reciba en virtud del uso del de POS y a tomar todas las medidas de seguridad necesarias para impedir cualquier acción fraudulenta. El Usuario acepta expresamente y se obliga a abstenerse de almacenar, usar o transmitir, o permitir que terceros directa o indirectamente almacenen, usen o transmitan información personal y de las Tarjetas de terceros obtenida a través de las transacciones procesadas con el linkingPos.</p>

            <h3>10- Declaraciones de linkingPos</h3>
            <p><strong>10.1.</strong> En ningún caso linkingPos será responsable por lucro cesante, o por cualquier otro daño y/o perjuicio que haya podido sufrir el Usuario debido a las transacciones realizadas o no realizadas mediante el uso de linkingPos. Asimismo, linkingPos no garantiza el acceso y uso continuado o ininterrumpido o exento de errores o virus de linkingPos. linkingPos realizará sus mayores esfuerzos para que el servicio linkingPos. esté libre de vicios, defectos y no se vea interrumpido. Los Usuarios no podrán imputarle responsabilidad alguna a linkingPos ni exigir resarcimiento alguno en virtud de perjuicios resultantes de las mencionadas dificultades, así como por cualquier otra clase de daños, incluyendo daños indirectos, especiales o consecuentes que surjan o experimenten los Usuarios.</p>
            <p><strong>10.2.</strong> El acceso del Usuario al servicio linkingPos depende de servicios de terceros (tales como, por ejemplo, servicios de telefonía, internet y de datos móviles). El Usuario acepta que linkingPos.com (DE INSPIRATIONAL GROWTH S.A.) no tiene ninguna responsabilidad relacionada con el cumplimiento de servicios de terceros. En particular, el Usuario acepta que la conexión telefónica, a Internet o a datos móviles necesaria para el funcionamiento del servicio linkingPos es a su exclusivo cargo.</p>
            <p><strong>10.3.</strong> El Lector se provee con garantía de 1 (un) año contra defectos de fabricación. Para solicitar el reemplazo o recambio del Lector, el Cliente deberá comunicar los inconvenientes a IinkingPos, y esta hará sus mejores esfuerzos para brindar soporte de reparación del Lector en los casos de fallas del equipo, no así en los supuestos de daños causados por el mal uso del Lector, por causas imputables al Usuario o por casos fortuitos o de fuerza mayor.</p>
            <p><strong>10.4.</strong> linkingPos no se responsabiliza por los daños que el usuario o cualquier tercero sufra como consecuencia del uso indebido y/o no autorizado del servicio linkingPos. En caso de que cualquier tercero inicie cualquier tipo de reclamo o acción legal, administrativa o extrajudicial contra I , sus accionistas, controlantes, controladas, filiales o subsidiarias, funcionarios, empleados, directores, agentes y empleados, derivada del uso del servicio linkingPos por parte del Usuario, éste exime y se compromete a mantener indemne de toda responsabilidad a linkingPos y a sus accionistas, controlantes, controladas, filiales o subsidiarias, funcionarios, empleados, directores, agentes, representantes y apoderados. El Usuario acepta expresamente que linkingPos podrá debitar de los Fondos en su Cuenta (existentes o que ingresen en el futuro) cualquier importe debido por el Usuario en concepto de reparación de daños y perjuicios.</p>
            <p><strong>10.5.</strong> En cualquier momento, linkingPos podrá disponer la cancelación de la Cuenta del Usuario y/o retirar la autorización para el uso del servicio linkingPos del Usuario sin expresión de causa y sin incurrir en responsabilidad alguna como consecuencia del ejercicio de dicha facultad. Asimismo, en caso de incumplimiento por parte del Usuario de las estipulaciones de estos Términos y Condiciones de Uso, y/o de la legislación aplicable, linkingPos podrá dar por terminada cualquier relación contractual con el Cliente/Usuario, sin aviso previo, reservándose el derecho de reclamar los daños y perjuicios que tal incumplimiento le haya causado.</p>
            <h3>11- Modificación de los Términos y Condiciones de Uso</h3>
            <p>LinkingPos podrá modificar en cualquier momento las Condiciones de Uso, notificando los cambios al Usuario publicando una versión actualizada de dichos términos y condiciones en el sitio con expresión de la fecha de la última modificación. Todos los términos modificados entrarán en vigor a los 10 (diez) días de su publicación.</p>
            <h3>12- Jurisdicción y ley aplicable</h3>
            <p>Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República Argentina. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida a los tribunales nacionales en lo comercial con asiento en la Ciudad de Mendoza, con renuncia expresa de todo otro fuero y/o jurisdicción.</p>
            <h3>13- Revocación</h3>
            <p><strong>El Usuario podrá retornar el Lector en cualquier momento</strong>, mediante aviso a linkingPos de su intención de revocación a través del canal habilitado a tal fin, y con una antelación de 30 días a la fecha de efectivo retiro del equipo por parte de linkingPos. La devolución del equipo lector implica la terminación del uso del linkingPos.</p>
        </div>
	</Layout>
)
	
export default injectIntl(EmpresasPage)
	