import React from 'react'
import logo from '../images/logo.svg'
import { useIntl, Link } from 'gatsby-plugin-intl';
import whatsapp from '../images/icons/whatsapp-brands.svg'

const Header = ({ location }) => {

	const intl = useIntl()

	const getSignupLink = () => {
		switch (location) {
			case 'index':
				return `/${intl.locale}/#new-accounts`
			case 'individuos':
				return '/users/signups/new'
			case 'empresas':
				return '/users/business/new'
			default:
				return '/'
		}
	}

	return (
		<header>
			<div className="container">
				<div className="logo-container"><Link to="/"><img src={logo} alt="logo" /></Link></div>
				<nav className="nav-container">
					<a rel="noreferrer" href="/users/sign_in" className="btn-light" id="login">Ingresar</a>
					<a rel="noreferrer" href={getSignupLink()} className="btn-primary m-r-20" id="signup">Crear tu cuenta gratis</a>
					<div className="btn-help" id="wsp"><a rel="noreferrer" href="https://api.whatsapp.com/send/?phone=5492614852103&text&app_absent=0" target="_blank" >Help <img className="m-l-10" src={whatsapp} width={25} alt="whatsapp-icon" /></a></div>
				</nav>
			</div>
		</header>
	)
}

export default Header
