import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import logo from '../images/logo_white.svg'

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-nav">
                        <div className="nav-heading">
                            <span>Mapa del Sitio</span>
                        </div>
                        <div className="nav-items">
                            <div className="items-col">
                                <ul>
                                    <li><Link to="/">Inicio</Link></li>
                                    <li><Link to="/individuos">Pagar</Link></li>
                                </ul>
                            </div>
                            <div className="items-col">
                                <ul>
                                    <li><Link to="/empresas">Cobrar</Link></li>
                                    <li><a rel="noreferrer" href="https://api.whatsapp.com/send/?phone=5492614852103&text&app_absent=0" target="_blank">Help</a></li>
                                </ul>
                            </div>
                            <div className="items-col">
                                <ul>
                                    <li><Link to="/contacto">Contacto</Link></li>
                                    <li><Link to="/legales">Legales</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-logo">
                        <div className="logo-container"><img src={logo} width={166} alt="logo"/></div>         
                    </div>
                </div>
                <div className="footer-bottom">
                    <span>linkingPos ofrece servicios de cobro y pago con tarjetas de débito y crédito en Argentina y México. Los fondos acreditados en cuentas de pago son depósitos de terceros.</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer
