import React from 'react';
import bridgestone from '../images/bridgestone.png';
import lascanas from '../images/lascanas.jpg';
import ipc from '../images/ipc.jpg';
import mm from '../images/mm.jpg';
import hc from '../images/marca_hiper_horizontal.png';
import Slider from 'react-slick';

const settings = {
	focusOnSelect: true,
	dots: false,
	arrows: true,
	infinite: false,
	speed: 500,
	slidesToShow: 1,
	rows: 1,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
};


const DualBlock = ({ page }) => {
	return (
		<div className={`dual-block ${page}`}>
			<div className="container">
				<div className="slider-container">
					<Slider {...settings}>

						<div className="all-content">
							<div className="content">
								<p><strong>LinkingPos</strong> nos permitió incorporar opciones y promociones de pago para nuestros clientes en forma rápida y eficiente, no solo en el canal de venta tradicional sino también en canales digitales a través de una excelente atención personalizada y profesional.</p>
							</div>
							<div className="bottom">
								<div className="author m-t-20">
									<span className="name">Mackenna Gauna</span>
								</div>
								<div className="logo"><img src={hc} width={180} style={{ marginTop: '30px' }} alt="" /></div>
							</div>
						</div>

						<div className="all-content">
							<div className="content">
								<p>“<strong>LinkingPos</strong> aporta soluciones en el cobro con tarjetas a mi negocio, brinda además servicios de transferencias y pagos de impuestos, tasas y servicios, que lo transforman en una opción más eficiente y económica al home banking tradicional”.</p>
							</div>
							<div className="bottom">
								<div className="author m-t-20">
									<span className="name">Marcelo De Antonio</span>
									<span className="bajada">Manager de Valle Grande Neumáticos</span>
								</div>
								<div className="logo"><img src={bridgestone} width={180} style={{ marginTop: '30px' }} alt="" /></div>
							</div>
						</div>

						<div className="all-content">
							<div className="content">
								<p>Con <strong>linkingPos</strong> brindamos soluciones en el proceso de cobro para la venta de piscinas y accesorios, en toda nuestra red comercial de Argentina.</p>
							</div>
							<div className="bottom">
								<div className="author m-t-20">
									<span className="name">Mariano Paz</span>
									<span className="bajada">Director de Marketing</span>
								</div>
								<div className="logo"><img src={ipc} height={80} style={{ marginTop: '30px' }} alt="" /></div>
							</div>
						</div>

						<div className="all-content">
							<div className="content">
								<p>Utilizamos <strong>linkingPos</strong> porque es una herramienta muy útil para la realización de cobros con tarjetas de débito y crédito. Gracias a las funciones de su plataforma, podemos transferir pagos y depósitos a nuestros proveedores.</p>
							</div>
							<div className="bottom">
								<div className="author m-t-20">
									<span className="name">Julián Martin</span>
									<span className="bajada">Administración</span>
								</div>
								<div className="logo"><img src={mm} height={80} style={{ marginTop: '30px' }} alt="" /></div>
							</div>
						</div>

						<div className="all-content">
							<div className="content">
								<p>Implementamos <strong>linkingPos</strong> por ofrecernos una importante variedad de soluciones de cobro que hacen evolucionar nuestro negocio. Actualmente nos permite realizar ventas con link de pago, otorgando celeridad a nuestros procesos comerciales y a un costo menor que los métodos de cobros tradicionales, ofrecidos por bancos. </p>
							</div>
							<div className="bottom">
								<div className="author m-t-20">
									<span className="name">Marta Galán</span>
									{/* <span className="bajada">Administración</span> */}
								</div>
								<div className="logo"><img src={lascanas} height={80} style={{ marginTop: '30px' }} alt="" /></div>
							</div>
						</div>

					</Slider>
				</div>
			</div>
		</div>
	)
}

export default DualBlock
