import React from 'react'
import { useIntl } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router'

const ModuleConsumos = ({ location }) => {

    const intl = useIntl()

    const getQueryLink = () => {
        switch (location) {
            case 'index':
                return `/${intl.locale}/#consulta-consumos`
            case 'empresas':
                return `/${intl.locale}/empresas#consulta-consumos`
            default:
                return '/'
        }
    }

    return (
        <div className="module-consumos">
            <div className="module-content">
                <p>¿Tenés dudas sobre un consumo?</p>
                <a href={getQueryLink()} className="btn-primary-dark">Consultar</a>
            </div>
        </div>
    )
}

export default ModuleConsumos
