import React, { useState } from 'react'
import dateFormat from 'dateformat'

const TransactionQuery = ({ page }) => {

    const [amount, setAmount] = useState("")
    const [date, setDate] = useState("")
    const [number, setNumber] = useState("")
    const [installments, setInstallments] = useState("")
    const [error, setError] = useState()
    const [results, setResults] = useState(null)

    const submitForm = async (ev) => {

        ev.preventDefault();

        if (amount && date && number && installments) {
            await fetch(`/search?amount=${amount}&date=${dateFormat(date, "UTC:dd-mm-yyyy")}&number=${number}&number_payments=${installments}`, { method: 'GET' })
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    if (data.message) {
                        setError(true)
                    } else {
                        setResults(data.trx)
                    }
                })
                .catch((e) => {
                    setError(true)
                    console.log('error', e)
                })
        }

    }

    const resetForm = () => {
        setResults(null)
        setError(null)
        setAmount(null)
        setDate(null)
        setNumber(null)
        setInstallments(null)
    }

    return (
        <div className={`query-block ${page}`} id="consulta-consumos">
            <div className="container">
                <div className="form-container">
                    <div className="form-result">
                        {(results || error) && <h3 className="text-center">Resultados de la consulta:</h3>}
                        {error && <p className="text-center">No pudimos encontrar la operación solicitada. Para más información puede contactarse por teléfono o WhatsApp al 261 4852103.</p>}
                        {results && <div className="results">
                            <div className="col">
                                <p><strong>Empresa:</strong> {results.empresa}</p>
                                <p><strong>Dirección:</strong> {results.direccion}</p>
                                <p><strong>Cuit:</strong> {results.cuit}</p>
                                <p><strong>Número de tarjeta:</strong> {results.numero_tarjeta}</p>
                                {results?.tarjeta_brand && <p><strong>Emisor Tarjeta:</strong> {results?.tarjeta_brand}</p>}
                            </div>
                            <div className="col">
                                <p><strong>Banco Tarjeta:</strong> {results.tarjeta_banco}</p>
                                <p><strong>Fecha:</strong> {dateFormat(results.fecha, 'dd-mm-yyyy')}</p>
                                <p><strong>Importe:</strong> ${results.importe}</p>
                                <p><strong>Cuotas:</strong> {results.cuotas}</p>
                            </div>
                        </div>}
                        {(results || error) && <div className="btn-container">
                            <button onClick={resetForm} className="btn-primary">Buscar otro consumo</button>
                        </div>}
                    </div>
                    {(!results && !error) && <form onSubmit={submitForm} className="contact-form-query">
                        <div className="heading">
                            <h3 className="text-center">Consulta de consumos</h3>
                            <p className="text-center">Aquí puedes verificar los consumos realizados con linkingPos</p>
                        </div>
                        <div className="input-block">
                            <label htmlFor="date">Fecha*</label>
                            <input type="date" onChange={(e) => setDate(e.target.value)} value={date} required placeholder="10/10/2020" name="date" />
                        </div>
                        <div className="input-block">
                            <label htmlFor="installments">Cantidad de cuotas*</label>
                            <input maxLength="2" onChange={(e) => setInstallments(e.target.value)} value={installments} type="text" required placeholder="6" name="installments" />
                        </div>
                        <div className="input-block monto">
                            <label htmlFor="monto">Importe de la cuota*</label>
                            <input maxLength="10" type="text" onChange={(e) => setAmount(e.target.value)} value={amount} required placeholder="10,99" name="monto" />
                        </div>
                        <div className="input-block">
                            <label htmlFor="card">Últimos 4 dígitos de la tarjeta*</label>
                            <input maxLength="4" onChange={(e) => setNumber(e.target.value)} value={number} type="text" required placeholder="1234" name="card" />
                        </div>
                        <div>
                            <button type="submit" className="btn-primary">Consultar</button>
                        </div>
                    </form>}
                </div>
            </div>
        </div>
    )
}

export default TransactionQuery
